<template>
  <div class="common-wrapper" id="commonwrapper">
    <div class="table-header">
      <categorySearch :source="filterSource" class="searchBlock" @change="initTableData" />
      <a-button type="primary" @click="handleAdd">添加</a-button>
    </div>
    <basic-table ref="tableRef2" v-bind="tableConfig2" @page-change="pageChange">
      <template #slotId="{ row }">
        <router-link :to="`/backManage/businessManage/businessInfo?id=${row.id}`">{{ row.id }}</router-link>
      </template>
      <template #slotStatus="{ row }">
        <span>{{ row.status == 0 ? '正常' : '停用' }}</span>
      </template>
    </basic-table>

    <a-modal v-model:visible="visible" width="906px" okText="下一步" @ok="next" @cancel="handleCancel">
      <template #title>
        <span class="modal-title">{{ isEditing ? '编辑' : '添加' }}租户</span>
        <span class="modal-sub-title">填写基本信息</span>
      </template>
      <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 8 }">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="name" label="租户名称：">
              <a-input v-model:value="formState.name" placeholder="请输入租户名称" :maxlength="50"></a-input>
            </a-form-item>
            <a-form-item name="code" label="租户编码：">
              <a-input v-model:value="formState.code" placeholder="请输入英文或数字字符，不超过8个字符" :disabled="isEditing" :maxlength="8"></a-input>
            </a-form-item>
            <a-form-item name="socialCreditCode" label="统一社会信用代码：">
              <a-input v-model:value="formState.socialCreditCode" placeholder="请输入18位有效企业社会信用编码" :disabled="isEditing" :maxlength="18"></a-input>
            </a-form-item>
            <a-form-item name="address" label="企业地址：">
              <a-input v-model:value="formState.address" placeholder="请输入企业地址" :maxlength="500"></a-input>
            </a-form-item>
            <a-form-item name="businessLicense" label="营业执照：">
              <upload-image v-model:value="formState.businessLicense" @change="toValidate(['businessLicense'])" />
            </a-form-item>
            <a-form-item name="logo" label="企业logo：">
              <upload-image v-model:value="formState.logo" @change="toValidate(['logo'])" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="adminUserName" label="管理员账号：">
              <a-input v-model:value="formState.adminUserName" placeholder="请输入管理员账号" :disabled="isEditing"
                :maxlength="50"></a-input>
            </a-form-item>
            <a-form-item name="adminNickName" label="管理员姓名：">
              <a-input v-model:value="formState.adminNickName" placeholder="请输入管理员姓名" :disabled="isEditing"
                :maxlength="50"></a-input>
            </a-form-item>
            <a-form-item name="adminMobile" label="管理员电话：">
              <a-input v-model:value="formState.adminMobile" placeholder="请输入11位有效手机号" :disabled="isEditing"></a-input>
            </a-form-item>
            <a-form-item name="adminEmail" label="管理员邮箱：">
              <a-input v-model:value="formState.adminEmail" placeholder="请输入国内有效邮箱" :disabled="isEditing"></a-input>
            </a-form-item>
            <a-form-item name="reportingCycle" label="月报表周期：">
                <a-select v-model:value="formState.reportingCycle"  placeholder="请选择">
                        <a-select-option v-for="item in monthPridOptions" :key="item.value" :value="item.value">{{
                            item.label
                        }}</a-select-option>
                    </a-select>
              </a-form-item>
            <a-form-item name="remark" label="备注：">
              <a-textarea v-model:value="formState.remark" placeholder="请输入备注"></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="visible2" width="906px" @cancel="handleCancel">
      <template #title>
        <span class="modal-title">{{ isEditing ? '编辑' : '添加' }}租户</span>
        <span class="modal-sub-title">分配租户模块权限</span>
      </template>
      <ModuleMenu ref="moduleMenu" v-if="visible2" :modules="modules" :defaultActiveModules=activeModules />
      <template #footer>
        <a-button @click="prev">上一步</a-button>
        <a-button type="primary" :loading="loading" @click="handleSave">{{ isEditing ? '保存' : '创建' }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'TenantManage'
// })
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from '@/components/basic/table'
import { reactive, ref, onMounted } from 'vue'
import { apiTenant } from "@/api/tenant.js";
import { message, Modal } from 'ant-design-vue';
import ModuleMenu from "./components/moduleMenu.vue";
import UploadImage from '../../../../components/basic/upload/uploadImage.vue';
import { codeReg } from '@/utils/validate';
import { apiDictListByCode } from "@/api/common";

const monthPridOptions = ref([])

const filterSource = [
  {
    label: '租户名称',
    type: 'input',
    bindKey: 'name',
    placeholder: '请输入'
  },
  {
    label: '租户状态',
    type: 'select',
    bindKey: 'status',
    options: [
      {
        label: "正常",
        value: 0
      },
      {
        label: "停用",
        value: 1
      },
    ]
  },
  {
    label: '管理员电话',
    type: 'input',
    bindKey: 'adminMobile',
    placeholder: '请输入'
  },
  {
    label: '管理员邮箱',
    type: 'input',
    bindKey: 'adminEmail',
    placeholder: '请输入'
  }
]

const tableConfig2 = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
  columns: [
    {
      title: "ID",
      type: "customize",
      slotName: "slotId",
    },
    {
      title: "租户名称",
      type: "text",
      key: "name",
      width: 150
    },
    {
      title: "租户编码",
      type: "text",
      key: "code",
      width: '100px'
    },
    {
      title: "租户状态",
      type: "customize",
      slotName: "slotStatus",
      width: '100px'
    },
    {
      title: "管理员",
      type: "text",
      key: "adminNickName",
    },
    {
      title: "管理员邮箱",
      type: "text",
      key: "adminEmail",
      width: 250
    },
    {
      title: "管理员电话",
      type: "text",
      key: "adminMobile",
    },
    {
      title: "办公地址",
      type: "text",
      key: "address",
    },
  ],
  action: {
    title: "操作",
    width: 100,
    fixed: "right",
    items: [
      {
        icon: "FormOutlined",
        iconTip:'编辑',
        type: "icon",
        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip:'删除',
        type: "icon",
        onClick: (row) => {
          handleDelete(row.id);
        },
      },
    ],
  },
})
const tableRef2 = ref();

const modules = ref([])
let activeModules = reactive({})

const loading = ref(false);
const visible = ref(false);
const visible2 = ref(false);
const isEditing = ref(false);

const formState = reactive({
  name: '',
  code: '',
  socialCreditCode: '',
  businessLicense: '',
  logo: '',
  address: '',
  remark: '',
  adminUserName: '',
  adminNickName: '',
  adminEmail: '',
  adminMobile: '',
  reportingCycle:''
})

const validateDuplicate = async ({ field }, value) => {
  let m = {
    [field]: value,
    tenantId:formState.id
  }
  if (value) {
    let { result } = await apiTenant.validation(m)
    console.log(result[field]);
    if (result[field]) {
      return Promise.resolve();
    }
    return Promise.reject('该项值已重复');
  }
  return Promise.resolve(); 
}

const rulesRef = reactive({
  name: [
    { required: true, message: '请输入租户名称' },
    { trigger: 'blur', validator: validateDuplicate }
  ],
  code: [
    { required: true, message: '请输入租户编码' },
    {
      pattern: codeReg,
      message: '租户编码只允许字母、数字组成，首位只能为字母,不能超过8位'
    },
    { trigger: 'blur', validator: validateDuplicate }
  ],
  socialCreditCode: [
    { required: true, message: '请输入统一社会信用代码' },
    { trigger: 'blur', validator: validateDuplicate }
  ],
  address: [{ required: true, message: '请输入企业地址' }],
  businessLicense: [{ required: true, message: '请上传营业执照' }],
  logo: [{ required: true, message: '请上传企业logo' }],
  adminNickName: [
    { required: true, message: '请输入管理员账号' },
  ],
  adminUserName: [
    { required: true, message: '请输入管理员姓名' },
    { trigger: 'blur', validator: validateDuplicate }
  ],
  adminMobile: [
    { required: true, message: '请输入管理员电话' },
    {
      pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
      message: '请输入正确手机号'
    },
    { trigger: 'blur', validator: validateDuplicate }
  ],
  adminEmail: [
    { required: true, message: '请输入管理员邮箱' },
    {
      pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '邮箱格式不正确'
    },
    { trigger: 'blur', validator: validateDuplicate },
  ],
  reportingCycle: [
    { required: true, message: '请选择月报表周期' },
  ],
});

const formRef = ref()
const moduleMenu = ref();

onMounted(() => {
  initTableData()
  initMenu()
  initMonthPridItems()
})

const initTableData = (searchItems = {}) => {
  apiTenant.list({
    pageNo: tableRef2.value.paginationProps.current,
    pageSize: tableRef2.value.paginationProps.pageSize,
    ...searchItems
  }).then(res => {
    if (res.errorCode === '200') {
      let result = res.result
      tableRef2.value.dataSourceRef = result.records || [];
      tableRef2.value.paginationProps.total = result.total;
    }
  })

};

const initMonthPridItems = () => {
  apiDictListByCode("REPORTING_CYCLE").then((res) => {
    monthPridOptions.value = res.result;
    formState.reportingCycle = res.result&&res.result[0]&&res.result[0].value
  });
};

const initMenu = () => {
  apiTenant.menus().then(res => {
    if (res.errorCode === '200') {
      let result = res.result
      result.forEach(module => {
        filterEmptyArray(module.menus)
      })
      modules.value = result
    }
  })
}

const filterEmptyArray = (arr) => {
  arr.forEach(e => {
    if (e.children.length === 0) {
      delete e.children
    } else {
      filterEmptyArray(e.children)
    }
  });
}
const pageChange = (pageIndex) => {
  tableRef2.value.paginationProps.current = pageIndex
  initTableData()
}


const handleAdd = () => {
  isEditing.value = false
  visible.value = true
}

const handleEdit = (item) => {
  visible.value = true
  isEditing.value = true
  apiTenant.detail(item.id).then(res => {
    if (res.errorCode == '200') {
      let result = res.result
      formState.id = result.id
      for (const key in formState) {
        formState[key] = result[key]
      }
      activeModules = result.modulePermissions
    }
  })
}

const handleDelete = (id) => {
  Modal.confirm({
    title: '提醒',
    content: '请确认是否删除？',
    onOk() {
      return new Promise((resolve) => {
        apiTenant.delete(id).then(res => {
          if (res.errorCode === '200') {
            message.success('删除成功');
            initTableData()
          }
        }).finally(() => {
          resolve()
        })
      })
    }
  });
}

const handleCancel = () => {
  visible.value = false;
  visible2.value = false;
  formRef.value.resetFields();
  delete formState.id
  activeModules = {}
}

const next = () => {
  formRef.value.validateFields().then(() => {
    visible.value = false
    visible2.value = true
  });
}

const prev = () => {
  visible2.value = false
  visible.value = true
}

const handleSave = () => {
  let m = {}
  let permissions = moduleMenu.value.getActivePermissions()
  let func = 'add'
  if (formState.id) {
    func = 'edit'
    m.id = formState.id
    m.name = formState.name
    m.businessLicense = formState.businessLicense
    m.logo = formState.logo
    m.address = formState.address
    m.remark = formState.remark
    m.permissions = permissions
    m.reportingCycle = formState.reportingCycle
  } else {
    m.tenant = formState;
    m.menu = { permissions }
  }
  loading.value = true
  apiTenant[func](m).then(() => {
    visible2.value = false;
    activeModules = {}
    initTableData()
    if (!formState.id) {
      Modal.info({
        title: '消息',
        content: `${formState.name}租户创建成功。相关信息，已经发送到 ${formState.adminEmail}。`,
        okText: '确认',
        onOk() {
          formRef.value.resetFields();
        }
      });
    }
  }).finally(() => {
    loading.value = false;
  })

}


const toValidate = (keys) => {
  formRef.value.validateFields(keys)
}
</script>
<style lang='less' scoped>
.table-header {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  line-height: 24px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.modal-sub-title {
  line-height: 24px;
  font-size: 16px;
  color: rgba(102, 102, 102, 0.85);
  margin-left: 12px
}
</style>