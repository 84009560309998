<template>
    <a-row class="modules" :gutter="24">
        <a-col class="menu" :span="6">
            <h2 class="menu-title">
                <appstore-outlined />应用模块
            </h2>
            <ul v-if="activeModule">
                <li :class="[item.moduleId === activeModule.moduleId ? 'menu-item menu-item-active' : 'menu-item']"
                    v-for="item in modules" :key="item.moduleId" @click="changeSubMenu(item)">
                    <span>{{ item.moduleName }}</span>
                    <check-outlined v-if="activeModules[item.moduleId]" />
                </li>
            </ul>
        </a-col>
        <a-col class="sub-menu" :span="18">
            <h2 class="sub-menu-title">菜单列表</h2>
            <a-table v-if="activeModule" :columns="menuColumns" :data-source="activeModule.menus"
                :row-selection="rowSelection" rowKey="permission" :pagination="false" />
        </a-col>
    </a-row>
</template>

<script setup>
import { AppstoreOutlined, CheckOutlined } from '@ant-design/icons-vue';
import { defineProps, ref, reactive, onMounted, computed, defineExpose } from 'vue';

const props = defineProps({
    modules: Array,
    defaultActiveModules: Object
})

// 当前table勾选的值
const selectedRowKeys = computed(() => {
  return activeModules[activeModule.value?.moduleId] || [];
});

// 当前勾选的模块
const activeModule = ref(null)

// 已经勾选的模块
const activeModules = reactive({})

onMounted(() => {
    console.log(props.modules)
    activeModule.value = props.modules[0]
    for (const key in props.defaultActiveModules) {
        activeModules[key] = props.defaultActiveModules[key] || []
    }
})

const menuColumns = [
    {
        title: '菜单标识',
        dataIndex: 'permission',
        key: 'permission',
    },
    {
        title: '中文显示',
        dataIndex: 'menuName',
        key: 'menuName',
    },
    {
        title: '菜单地址',
        dataIndex: 'menuPath',
        key: 'menuPath',
    },
]

const rowSelection = computed(() => {
    return {
        checkStrictly: false,
        selectedRowKeys: selectedRowKeys,
        onChange: (changableRowKeys) => {
            if (changableRowKeys.length === 0) {
                delete activeModules[activeModule.value.moduleId]
            } else {
                activeModules[activeModule.value.moduleId] = changableRowKeys
            }
        },
    }
})

const changeSubMenu = (item) => {
    activeModule.value = item
}

const getActivePermissions = () => {
    let permissions = []
    for (const key in activeModules) {
        permissions = permissions.concat(activeModules[key])
    }
    return permissions
}

defineExpose({
    getActivePermissions,
})

</script>
<style lang='less' scoped>
.menu {
    min-height: 450px;
    border: 1px solid rgba(99, 116, 140, 0.2);
    padding: 0 8px;

    .anticon-appstore {
        padding: 0 6px;
    }

    &-title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 48px;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 0 16px;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        color: #4E5969;

        &-active {
            color: #0256FF;
            background: #F2F3F5;
        }
    }

}

.sub-menu {
    border: 1px solid rgba(99, 116, 140, 0.2);
    border-left: none;

    &-title {
        margin: 16px 0;
        padding-left: 10px;
        border-left: 3px solid #0256FF;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 16px;
    }
}

:deep .ant-modal-body {
    padding: 20px !important;
}

.modules {
    margin: 0 !important;
}

.anticon-check {
    color: #0256FF;
}
</style>